@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('../assets/fonts/graphik/style.css');
@import url('../assets/fonts/rawline/style.css');

@font-face {
  font-family: 'Custom';
  src: url('../assets/fonts/graphik/GraphikRegular.otf');
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/graphik/GraphikRegular.otf');
}

body {
  @apply font-custom;
  font-family: 'Graphik', 'Rawline', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
