/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  src: local('Graphik Regular'), url('GraphikRegular.otf') format('otf');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 400;
  src: local('Graphik Italic'),
    url('GraphikRegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 100;
  src: local('Graphik Thin'), url('GraphikThin.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 100;
  src: local('Graphik Thin Italic'),
    url('GraphikThinItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 200;
  src: local('Graphik ExtraLight'),
    url('GraphikExtralight.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 200;
  src: local('Graphik ExtraLight Italic'),
    url('GraphikExtralightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 300;
  src: local('Graphik Light'), url('GraphikLight.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 300;
  src: local('Graphik Light Italic'),
    url('GraphikLightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  src: local('Graphik Medium'), url('GraphikMedium.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 500;
  src: local('Graphik Medium Italic'),
    url('GraphikMediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  src: local('Graphik SemiBold'), url('GraphikSemibold.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 600;
  src: local('Graphik SemiBold Italic'),
    url('GraphikSemiboldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 700;
  src: local('Graphik Bold'), url('GraphikBold.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 700;
  src: local('Graphik Bold Italic'),
    url('GraphikBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 800;
  src: local('Graphik ExtraBold'), url('GraphikBlack.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 800;
  src: local('Graphik ExtraBold Italic'),
    url('GraphikBlackItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 900;
  src: local('Graphik Black'), url('GraphikSuper.otf') format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 900;
  src: local('Graphik Black Italic'),
    url('GraphikSuperItalic.otf') format('opentype');
}
