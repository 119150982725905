.auth-page-wrapper {
  min-height: 100vh;
}

.auth-page {
  background-color: var(--background);
  border: 0.5px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  box-shadow: var(--floating-box-shadow);
  --bs-gutter-x: 60px;
  margin-bottom: 100px;
  margin-top: 50px;
  max-width: 450px !important;
  padding: var(--bs-gutter-x);
}
